import React, { useState, useEffect, useRef } from 'react';
import {
  IoChatbubbleEllipses,
  IoLogoWhatsapp,
  IoSendSharp,
  IoChevronBack,
} from 'react-icons/io5';
import { MdEmojiEmotions, MdSettings, MdSearch } from 'react-icons/md';
import EmojiPicker from 'emoji-picker-react';
import CustomLayout from '../../layouts/Customlayout';
import axios from 'axios';
import config from '../../config';
import { Avatar,message,Tabs,Switch,Tooltip,Skeleton } from 'antd';
import dayjs from 'dayjs';
import ChatView from './ChatView';
import { UserOutlined,SmileOutlined,RobotOutlined,WhatsAppOutlined,MessageOutlined,InstagramOutlined,MoreOutlined} from '@ant-design/icons';
import WhatsappChat from './WhatsappChat';
import InstagramChat from './InstagramChat';
import ChatImage from './message-63-32.png'
import ChatMessage from './ChatMessage';

function WebsiteChats({ chats, selectedChat, setSelectedChat, searchQuery, setSearchQuery, isMobile,isLoading }) {
  const [activeTab, setActiveTab] = useState("1"); // "1" for My Chats, "2" for All Chats
  const userId = JSON.parse(localStorage.getItem('UserObject'))?.id; // Assuming user info is stored in localStorage
  const isInvited = localStorage.getItem("invited") === "true"; // Check if user is invited


  const filteredChats = chats
  .filter((chat) => {
    if (isInvited && activeTab === "1") {
      return chat?.assigneeID === userId; 
    }
    // Show all chats if the user is not invited or activeTab is "2"
    return true;
  })
  .filter((chat) =>
    chat?.mail?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (date) => {
    return date ? dayjs(date).format('MMMM D, YYYY, h:mm A') : 'N/A';
  };

  const items = [
    {
      key: "1",
      label: "My Chats",
    },
    {
      key: "2",
      label: "All Chats",
    },
  ];

  return (
    <aside
      className={` lg:w-1/3 bg-white shadow-md overflow-y-auto h-[80vh] ${
        isMobile && selectedChat ? 'hidden' : 'block'
      }`}

    >
      <div className='flex justify-center items-center'>
      {isInvited && (
          <div>
            <Tabs
              defaultActiveKey="1"
              onChange={(key) => setActiveTab(key)} // Update activeTab state
              items={items}
              centered
            />
          </div>
        )}
        {/* <Tabs  defaultActiveKey="1" items={items}   onChange={(key) => setActiveTab(key)} /> */}
      </div>
      <div className="p-4 border-b flex items-center space-x-3">
        

        <MdSearch size={20} className="text-gray-500" />
        <input
          type="text"
          placeholder="Search chats..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 rounded-md border border-gray-300 focus:outline-none "
        />
      </div>
      <div className="chat-logs-container h-[calc(100vh-8rem)] overflow-y-auto">
        {
          isLoading?(


            <div className='ml-4 flex-col'>
            <div className='mb-4 mt-4'>
            <Skeleton 
            active={isLoading}
              avatar
              paragraph={{
                rows: 1,
                width:140
              }}
            />
            </div>
            <div className='mb-4'>
            <Skeleton 
            active={isLoading}
              avatar
              paragraph={{
                rows: 1,
                width:140
              }}
            />
            </div>

            <div className='mb-4'>
            <Skeleton 
            active={isLoading}
              avatar
              paragraph={{
                rows: 1,
                width:140
              }}
            />
            </div>

            <div className='mb-4'>
            <Skeleton 
            active={isLoading}
              avatar
              paragraph={{
                rows: 1,
                width:140
              }}
            />
            </div>



            </div>
          ):(
            <ul className="divide-y">
            {filteredChats.map((chat, idx) => (
              <li
                key={idx}
                className={`p-4 flex items-center space-x-3 hover:bg-purple-200 cursor-pointer transition ${
                  selectedChat?.id === chat.id ? 'bg-purple-200  border-y-0  border-l-4 border-x-[#7F28F8]' : ''
                }`}
                onClick={() => setSelectedChat(chat)}
              >
              <Avatar
                size={40}
                style={{
                  backgroundColor: '#fde3cf',
                  color: '#f56a00',
                  fontWeight: 'bold',
                }}
              >
                {chat?.mail
                  ? chat?.mail
                      .split(' ')
                      .map((n) => n[0]?.toUpperCase()) // Extract and capitalize the first letter of each word
                      .join('')
                      .slice(0, 2) // Initials for avatar
                      
                  : 'NA'}
              </Avatar>
                <div className="flex-grow">
                  <h3 className="font-medium">{chat.mail}</h3>
                  <p className="text-sm text-gray-500">{formatDate(chat.date)}</p>
                </div>
              </li>
            ))}
        
          </ul>
          )
        }



     
      </div>
      
    </aside>
  );
}


function InstagramChats({ chats, selectedChat, setSelectedChat, searchQuery, setSearchQuery, isMobile }) {
  const filteredChats = chats?.filter((chat) =>
    chat?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <aside
      className={`w-full lg:w-1/3  bg-white shadow-md overflow-y-auto h-[80vh] ${
        isMobile && selectedChat ? 'hidden' : 'block'
      }`}
    >
      <div className="p-4 border-b flex items-center space-x-3">
        <MdSearch size={20} className="text-gray-500" />
        <input
          type="text"
          placeholder="Search chats..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 rounded-md border border-gray-300 focus:outline-none"
        />
      </div>
      <div className="chat-logs-container h-[calc(100vh-8rem)] overflow-y-auto">
        <ul className="divide-y">
          {filteredChats?.map((chat, idx) => (
            <li
              key={idx}
              className={`p-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer transition ${
                selectedChat?.id === chat.id ? 'bg-gray-200' : ''
              }`}
              onClick={() => setSelectedChat(chat)}
            >

            <Avatar
              size={40}
              src={chat?.profile}
              >
            </Avatar>
              <div className="flex-grow">
                <h3 className="font-medium">{chat?.name}</h3>
                {/* <p className="text-sm text-gray-500">{chat?.last_message}</p> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

function WhatsAppChats({ chats, selectedChat, setSelectedChat, searchQuery, setSearchQuery, isMobile }) {
  const filteredChats = chats?.filter((chat) =>
    chat?.display_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <aside
      className={`w-full lg:w-1/3  bg-white shadow-md overflow-y-auto h-[80vh] ${
        isMobile && selectedChat ? 'hidden' : 'block'
      }`}
    >
      <div className="p-4 border-b flex items-center space-x-3">
        <MdSearch size={20} className="text-gray-500" />
        <input
          type="text"
          placeholder="Search chats..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 rounded-md border border-gray-300 focus:outline-none"
        />
      </div>
      <div className="chat-logs-container h-[calc(100vh-8rem)] overflow-y-auto">
        <ul className="divide-y">
          {filteredChats?.map((chat, idx) => (
            
            <li
            key={idx}
            className={`p-4 flex items-center space-x-3 hover:bg-green-100 cursor-pointer transition ${
              selectedChat?.id === chat.id ? 'bg-green-100  border-y-0  border-l-4 border-x-[#25D366]' : ''
            }`}
              onClick={() => setSelectedChat(chat)}
            >

            <Avatar
              size={40}
              style={{
                backgroundColor: '#fde3cf',
                color: '#f56a00',
                fontWeight: 'bold',
              }}
            >
              {chat?.display_name
                ? chat?.display_name
                    .split(' ')
                    .map((n) => n[0]?.toUpperCase()) // Extract and capitalize the first letter of each word
                    .join('')
                    .slice(0, 2) // Initials for avatar
                    
                : 'NA'}
            </Avatar>
              <div className="flex-grow">
                <h3 className={`font-medium  ${selectedChat?.id === chat.id ? 'text-green-600' : ''}`}>{chat?.display_name}</h3>
                <p className="text-sm text-gray-500">{chat?.last_message}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

function ChatArea({ selectedChat, messages, setMessages, messageInput, setMessageInput, handleSendMessage, isMobile, setSelectedChat,connected }) {
  const emojiPickerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [Opened,setOpen]=useState(false)
  const [teamMembers,setTeamMembers]=useState()
  const isInvited = localStorage.getItem("invited") === "true"; 
  const [aiEnabled, setAiEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state



  const checkChatbotStatus = async (sessionId, sessionType) => {
    try {
      const response = await axios.get(`${config.apiUrl}disable-chatbot/`, {
        params: {
          session_id: sessionId,
          session_type: sessionType,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        return response.data.disable_chatbot;
      }
    } catch (error) {
      console.error('Error fetching chatbot status:', error);
    }
  };

  useEffect(() => {
    const fetchChatbotStatus = async () => {
      const isDisabled = await checkChatbotStatus(selectedChat.user_session_id, 'normal');
      setAiEnabled(isDisabled); // Update toggle based on status
    };

    if (selectedChat) {
      fetchChatbotStatus();
    }
  }, [selectedChat]);


  const handleBotControl = async (checked) => {
    setIsLoading(true); // Start loading

    let formData = new FormData();
    formData.append('session_id', selectedChat.user_session_id);
    formData.append('session_type', 'normal');
    formData.append('toggle_action', checked); // Use the new value of the toggle

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
        Accept: 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}disable-chatbot/`,
        formData,
        headers
      );

      setAiEnabled(checked); // Update the toggle state only on success
    } catch (error) {
      console.error('Error toggling bot control:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  const handleUpdateData = (newData) => {
    setSelectedChat(newData);
  };


  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/administration/team/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setTeamMembers(response.data[0]?.memberships || []);
    } catch (error) {
      message.error('Failed to load team members.');
    } finally {
    }
  };

  useEffect(()=>{
    fetchTeamMembers();
  },[])

  const closeUserProfile = () => {
    setOpen(false)
  };

  const parseMessage = (message,isHtml) => {
    // Regex for image URLs (handling optional query parameters)
    const imageRegex = /\bhttps?:\/\/[^\s]+files[^\s]+\.(png|jpg|jpeg|gif|svg|webp)(\?[^\s]*)?/gi;
    // Regex for Shopify product links
    const shopifyLinkRegex = /\b[a-zA-Z0-9-]+\.myshopify\.com\/\S*track_id=\S*/gi;
    // Regex for general HTTPS links
    const httpsRegex = /\bhttps?:\/\/[^\s]+/gi;

    if (isHtml) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          style={{
            wordWrap: 'break-word',
            fontSize: '13px',
            color: 'black',
          }}
        />
      );
    }
  
    // Split the message into parts based on these patterns
    const parts = message?.split(/(\bhttps?:\/\/[^\s]+)/g);
  
    return parts?.map((part, index) => {
      // Case 1: Handle image URLs
      if (part.match(imageRegex)) {
        return (
          <img
            key={`img-${index}`}
            src={part}
            alt="Product Image"
            style={{ maxWidth: '100%', borderRadius: '5px', marginTop: '10px', marginBottom: '10px' }}
          />
        );
      }
      // Case 2: Handle Shopify product links
      else if (part.match(shopifyLinkRegex)) {
        const properUrl = part.startsWith('http') ? part : `https://${part}`;
        return (
          <a
            key={`link-${index}`}
            href={properUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: '#0d6efd' }}
          >
            (Product Link)
          </a>
        );
      }
      // Case 3: Handle general HTTPS links
      else if (part.match(httpsRegex)) {
        return (
          <a
            key={`link-${index}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: 'blue' }}
          >
            (Link)
          </a>
        );
      }
      // Default case: Render text
      return <span key={`text-${index}`}>{part}</span>;
    });
  };

  const handleEmojiClick = (emojiObject) => {
    setMessageInput((prev) => prev + emojiObject.emoji);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const formatDate = (date) => {
    return date ? dayjs(date).format('MMMM D, YYYY, h:mm A') : 'N/A';
  };
  const formatTime = (time) => {

    if (time) {
      
      const [hour, minute] = time.split(':');
  

      
      let newHour = parseInt(hour, 10);
      const suffix = newHour >= 12 ? 'PM' : 'AM';
      newHour = newHour % 12 || 12;
      return `${newHour}:${minute} ${suffix}`;
      
    }
  }


  return (
    <main
      className={`flex-grow flex overflow-y-auto flex-col bg-white h-screen ${
        isMobile && !selectedChat ? 'hidden' : 'block'
      }`}
    >
      {selectedChat ? (
        <div className="flex flex-col h-[80vh]">
          <div className="flex items-center justify-between p-4 bg-gray-100 border-b">
            <button
              className="text-[#7F28F8] font-medium sm:hidden"
              onClick={() => setSelectedChat(null)}
            >
              <IoChevronBack size={24} />
            </button>
            <div>
              <div className='flex gap-2'>
                <div>
                <Avatar
                size={40}
                style={{
                  backgroundColor: '#fde3cf',
                  color: '#f56a00',
                  fontWeight: 'bold',
                }}
              >
                {selectedChat?.mail
                  ? selectedChat?.mail
                      .split(' ')
                      .map((n) => n[0]?.toUpperCase()) // Extract and capitalize the first letter of each word
                      .join('')
                      .slice(0, 2) // Initials for avatar
                      
                  : 'NA'}
              </Avatar>
                </div>
                  <div>
                  <h2 className="font-semibold">{selectedChat.mail || selectedChat.display_name}</h2>
                <span className="text-gray-500">{formatDate(selectedChat.date)}</span>
                  </div>

              </div>

            </div>

           
            <Tooltip
            title={
              isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                ? "You do not have control over this conversation"
                : aiEnabled
                ? "You are in control of the conversation"
                : "AI is responding to messages"
            }
            placement="bottom"
          >
            <div className="flex items-center gap-2">
              <Switch
                loading={isLoading}
                disabled={
                  isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                }
                onChange={handleBotControl} // Trigger API call on toggle
                checked={aiEnabled}
                className='bg-[#7F28F8]'
                checkedChildren={< UserOutlined/>} // Icon for "AI On"
                unCheckedChildren={<RobotOutlined />} // Icon for "AI Off"
              />
              {/* <span className="text-sm font-medium text-gray-700">
                {aiEnabled ? "Human Control" : "AI Enabled"}
              </span> */}
            </div>
          </Tooltip>



            <button onClick={()=>{setOpen(!Opened)}} className="text-gray-600 hover:text-[#7F28F8]">
              <MoreOutlined style={{fontSize:25}} />
            </button>
          </div>
          <div className="flex-grow p-4 overflow-y-auto bg-gray-50">
            <div className='flex justify-center'>
            {connected === "Connecting" && (
                <div style={{ color: "lightblue", fontSize: "15px" }}>Connecting ••</div>
              )}
              {connected === "Connected" && (
                <div style={{ color: "lightgreen", fontSize: "15px" }}>Connected •</div>
              )}
              {connected === "Disconnected" && (
                <div style={{ color: "lightcoral", fontSize: "15px" }}>Disconnected •</div>
              )}
            </div>

            {messages?.length > 0 ? (
              messages?.map((message, id) => (
                <div
                  key={id}
                  className={`flex flex-col ${
                    message?.isBusiness ? 'items-start' : 'items-end'
                  } mb-6`}
                > 

                    <div className='flex items-center gap-12'>
                    {message?.isBusiness && (
                              <Avatar
                                size={40}
                                style={{
                                  backgroundColor: '#fde3cf',
                                  color: '#f56a00',
                                  fontWeight: 'bold',
                                  marginBottom:'10px'
                                }}
                              >
                                {selectedChat?.mail
                                  ? selectedChat?.mail
                                      .split(' ')
                                      .map((n) => n[0]?.toUpperCase()) // Extract and capitalize the first letter of each word
                                      .join('')
                                      .slice(0, 2) // Initials for avatar
                                      
                                  : 'NA'}
                              </Avatar>
                        )}
                        
                    
                        </div>
                    <div
                    className={`max-w-xs p-4 rounded-lg shadow-md ${
                      message?.isBusiness
                        ? 'bg-white text-black'
                        : 'bg-[#7F28F8] text-white'
                    }`}
                    
                  >
                    
                    {message?.image_url && (
                    <div className="w-fit mb-2">
                      <img
                        src={message.image_url}
                        alt="Chat image"
                        style={{width:200}}
                        className={`rounded-md mt-2 max-w-xs ${
                          message?.isBusiness ? "border border-gray-300 shadow-sm" : "border border-green-400 shadow-sm"
                        }`}
                      />
                    </div>
                  )}


                    {message?.message && message?.message !== "[Image]" && (
               
                      <ChatMessage message={message?.message} />
                  )}

                  </div>
                  <div className='flex mt-2'>
                  {message?.isBusiness&&(
                          <span className='text-gray-500 text-[12px]'>{formatTime(message.time)}</span>
                  )}
                  </div>
              
                  <div className='flex mt-2'>
                  {!message?.isBusiness&&(
                          <span className='text-gray-500 text-[12px]'>{formatTime(message.time)}</span>
                  )}
                  </div>
              
                  {!message?.isBusiness && (
                    <span className="mt-2 text-xs text-gray-700 border  border-gray-300 px-2 py-1 rounded-full bg-gray-100 shadow-sm">
                      {message?.sender}
                    </span>
                  )}

                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">
                <span>
                No messages yet. Start the conversation!

                </span>
              </div>
            )}
          </div>

 

          <div className="p-4 bg-gray-100 border-t flex items-center space-x-3">
            <button
              type="button"
              onClick={() => setShowEmojiPicker((prev) => !prev)}
              className="text-gray-500 hover:text-purple-600"
            >
              <SmileOutlined style={{fontSize:25}}/>
            </button>
            {showEmojiPicker && (
              <div ref={emojiPickerRef} className="absolute bottom-16">
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
            <input
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              disabled={isInvited && selectedChat.assigneeID !==  JSON.parse(localStorage.getItem('UserObject')).id}
              placeholder="Type a message..."
              className="flex-grow p-3 border rounded-full focus:outline-none "
            />
            <button
              type="button"
              onClick={handleSendMessage}
              disabled={ isInvited && selectedChat.assigneeID !==  JSON.parse(localStorage.getItem('UserObject')).id}
              className=" bg-transparent text-white p-3  "
            >
              <IoSendSharp color='#7F28F8' size={24} />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <div>
            <div className='mb-4'>
            <img src={ChatImage} />
            </div>
            <div>
            <p className="text-gray-500">Select a chat to see the content.</p>

            </div>
          </div>
  
        </div>
      )}
      <ChatView visible={Opened} isInvited={isInvited}  onUpdateData={handleUpdateData}  close={() => { closeUserProfile() }} data={selectedChat} team={teamMembers}/>

    </main>
  );
}




export default function ModernMessaging() {
  const [tab, setTab] = useState('website'); // Tabs: website or whatsapp
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [whatsappChats, setWhatsappChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [instagramChats,setInstagramChats]=useState()
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [connected,setConnected]=useState("Connecting")
  const [messageLoading,setMessageLoading]=useState(true)
  const [isLoading,setIsLoading]=useState(true)

  const socketRef = useRef(null);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
    const fetchChats = async () => {

      try {
        if (tab === 'website') {
          setIsLoading(true)
          const response = await axios.get(`${config.apiUrl}chatlogs-get/${JSON.parse(localStorage.getItem('UserObject')).username}`, {
            headers: { Authorization: `Token ${localStorage.getItem('token')}` },
          });
          setChats(response.data);
          setIsLoading(false)
        } else if (tab === 'whatsapp') {
          const params = {
            sender_number: JSON.parse(localStorage.getItem('UserObject')).phone_number_id,
          };
          const response = await axios.get(`${config.apiUrl}whatsapp-chatlogs/`, {
            headers: { Authorization: `Token ${localStorage.getItem('token')}` },
            params,
          });
          setWhatsappChats(response.data);
        }else if(tab=== 'instagram'){
          const response = await axios.get(`${config.baseUrl}/instagram/chatlogs/`, {
            headers: { Authorization: `Token ${localStorage.getItem('token')}` },
          });
          setInstagramChats(response.data);
        }
        
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };

    fetchChats();
  }, [tab]);

  useEffect(() => {
    if (!selectedChat) return;
    const fetchMessages = async () => {
      setMessageLoading(true)
      try {
        if (tab === 'website') {
          const response = await axios.get(
            `${config.apiUrl}message-get/${selectedChat.user_session_id}`,
            {
              headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
              },
            }
          );
          setMessages(response.data);
          setMessageLoading(false)
        } else if (tab === 'whatsapp') {
          let formData = new FormData();
          formData.append('session_id', selectedChat.user_session_id);
      
          const headers = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${localStorage.getItem('token')}`,
              'Accept': 'application/json'
            }
          };
      
          axios.post(`${config.apiUrl}whatsapp-message-get/`, formData, headers).then((res) => {
            setMessages(res.data)
          })
        }
        else if (tab === 'instagram') {
          const params = {
            sender_id: selectedChat.session_id
          };
        
          const headers = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${localStorage.getItem('token')}`,
              'Accept': 'application/json'
            }
          };
        
          axios
            .get(`${config.baseUrl}/instagram/message/`, { params, ...headers })
            .then((res) => {
              setMessages(res.data);
            })
            .catch((err) => {
              console.error("Error fetching Instagram messages:", err);
            });
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    


    fetchMessages();

    const socket = new WebSocket(
      `${config.websocketUrl}${tab === 'website' ? 'chat/' : 'whatsapp_chat/'}${selectedChat.user_session_id}/`
    );
  

    socketRef.current = socket;

    socket.onmessage = (event) => {
    
      const rawMessage = JSON.parse(event.data);

      console.log(rawMessage)

      if (rawMessage?.additionalData){
        if (rawMessage.additionalData?.isBusiness){
          const newMessage = {
            message: rawMessage.message,
            isBusiness: rawMessage.additionalData?.isBusiness || true, 
            image_url:rawMessage.image_url
    
          };
          setMessages((prev) => [...prev, newMessage]);

          console.log(messages)
          console.log(rawMessage)
  
        }else if(rawMessage.additionalData?.sender === "AI"){
          const newMessage = {
            message: rawMessage.message,
            isBusiness: rawMessage.additionalData?.isBusiness || false, 
            sender:'AI'
          };
          setMessages((prev) => [...prev, newMessage]);
        }

      }
      else{

        if (rawMessage.message) {
          const newMessage = {
            message: rawMessage.message?.last_message,
            from_bot: rawMessage.message?.from_bot ?? true, // Default to true if undefined
            sender: rawMessage.message?.sender || null, // Default sender
            image_url:rawMessage.message?.image_url || null,
            audio_url:rawMessage.message?.audio_url || null,
            latitude:rawMessage.message?.latitude || null,
            longitude:rawMessage.message?.longitude || null,
  
          };
          setMessages((prev) => [...prev, newMessage]);
        }
      }





      

    
    };


    setConnected("Connecting");
  
    // When the WebSocket connection is successfully opened
    socket.onopen = () => {
      setConnected("Connected");
      console.log("WebSocket connection established");
    };
  
    // When the WebSocket connection is closed
    socket.onclose = () => {
      setConnected("Disconnected");
      console.log("WebSocket connection closed");
    };
  
    // When there's an error with the WebSocket
    socket.onerror = (error) => {
      setConnected("Error");
      console.error("WebSocket error:", error);
    };
  

    return () => {
      socket.close();
    };
  }, [selectedChat, tab]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadingImage,setIsUploadingImage]=useState(false)

  const handleSendMessage = async () => {
    if (!messageInput.trim() || !selectedChat) return;

    const newMessage = { message: messageInput, isBusiness: false,sender:JSON.parse(localStorage.getItem('UserObject')).first_name ||JSON.parse(localStorage.getItem('UserObject')).company_name };
    
    const additionalData = { isBusiness: false, sender: JSON.parse(localStorage.getItem('UserObject')).first_name ||JSON.parse(localStorage.getItem('UserObject')).company_name };
   
    const messagePayload = JSON.stringify({
      message: messageInput,
      additionalData: additionalData,
    });

    setMessages((prev) => [...prev, newMessage]);
    let temporalMessage= messageInput
    setMessageInput('');


    try {

      const payload = {
         message: temporalMessage,
         session_id:selectedChat.user_session_id,
         organization: JSON.parse(localStorage.getItem('UserObject')).username,
         sender: JSON.parse(localStorage.getItem('UserObject')).first_name ||JSON.parse(localStorage.getItem('UserObject')).company_name ,
         isBusiness: false,
        };
      await axios.post(`${config.apiUrl}message-create/`, payload, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });


      if (socketRef.current) {
        socketRef.current.send(messagePayload);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleWhatsappTextMsgSend = async () => {
    if ((!messageInput.trim() && !selectedImage) || !selectedChat) return; 
  
    const temporalMessage = messageInput; 
    setMessageInput('');
  
    const tempImageUrl = selectedImage ? URL.createObjectURL(selectedImage) : null;
    const tempMessageId = Date.now(); 
  
    setIsUploadingImage(true);
  
    // Create a temporary message object
    const tempJson = {
      id: tempMessageId,
      message: temporalMessage || "[Image]",
      image_url: tempImageUrl, // Show local preview first
      from_bot: true,
      sender: JSON.parse(localStorage.getItem('UserObject')).first_name || 
              JSON.parse(localStorage.getItem('UserObject')).company_name,
      status: 'uploading', // Mark as uploading
    };
  
    setMessages((messageList) => [...messageList, tempJson]); 
  
    let finalImageUrl = null;
  
    if (selectedImage) {
      console.log("selected Image")
      try {
        const formData = new FormData();
        formData.append('image', selectedImage);
  
        const uploadResponse = await axios.post(`${config.apiUrl}upload-image/`, formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (uploadResponse.status === 201) {
          finalImageUrl = uploadResponse.data.image_url;
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setIsUploadingImage(false);
        return;
      }
    }
  
    try {
      // Prepare the final message object
      const finalMessage = {
        id: tempMessageId, 
        message: temporalMessage || "[Image]",
        image_url: finalImageUrl || null, // Replace with real URL after upload
        from_bot: true,
        sender: tempJson.sender,
        status: 'sending', // Set to sending before final delivery
      };
  
      // Update UI to replace temp image with real URL
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? finalMessage : msg
        )
      );
  
      // Send WebSocket message
      if (socketRef.current) {
        socketRef.current.send(
          JSON.stringify({
            type: 'send_message',
            message: JSON.stringify(finalMessage),

          })
        );
      }
  
      // Send to backend
      let formData = new FormData();
      formData.append('message', temporalMessage);
      formData.append('sender_number', JSON.parse(localStorage.getItem('UserObject')).phone_number_id);
      formData.append('receiver_number', selectedChat.phone_number);
      formData.append('sender', finalMessage.sender);
      if (finalImageUrl) {
        formData.append('image_url', finalImageUrl);
      }
  
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
          Accept: 'application/json',
        },
      };
  
      await axios.post(`${config.apiUrl}whatsapp-message-send/`, formData, headers);
  
      // Mark as delivered
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: 'delivered' } : msg
        )
      );
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
  
      // Mark as failed
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: 'failed' } : msg
        )
      );
    } finally {
      setSelectedImage(null);
      setIsUploadingImage(false);
    }
  };
  
  



 

  return (
    <CustomLayout>
      <div className="h-screen flex flex-col bg-gray-50">
      <div className=" inset-0 p-4 border-b flex gap-6 rounded-lg backdrop-blur-sm bg-white/30">

    <Tooltip title="Website Messages" placement="top">
    <button
      className={`flex items-center p-2 rounded-full ${
        tab === 'website' ? 'bg-[#7F28F8] text-white' : 'bg-gray-200 text-gray-600'
      }`}
      onClick={() => {
        setTab('website');
        setSelectedChat(null);
        setMessages([]);
      }}
    >
      <MessageOutlined size={20} />
    </button>
    </Tooltip>
    <Tooltip title="Whatsapp Messages Section is Under Construction" placement="top">
      <button
        className={`flex items-center p-2 rounded-full ${
          tab === 'whatsapp' ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-600'
        }`}
        onClick={() => {
          setTab('whatsapp');
          setSelectedChat(null);
          setMessages([]);
        }}
      >
        <WhatsAppOutlined />
      </button>
    </Tooltip>

    <Tooltip title="Instagrams Messages Section is Under Construction" placement="top">
      <button
        className={`flex items-center p-2 rounded-full ${
          tab === 'instagram' ? 'bg-[#d62976]  text-white' : 'bg-gray-200 text-gray-600'
        }`}
        onClick={() => {
          setTab('instagram');
          setSelectedChat(null);
          setMessages([]);
        }}
      >
        <InstagramOutlined />
      </button>
    </Tooltip>
  </div>
        <div className="flex-grow flex flex-col sm:flex-row bg-white">
        {tab === 'website' ? (
          <WebsiteChats
            chats={chats}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isMobile={isMobile}
            isLoading={isLoading}
          />
        ) : tab === 'whatsapp' ? (
          <WhatsAppChats
            chats={whatsappChats}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isMobile={isMobile}
          />
        ) : (
          <InstagramChats
            chats={instagramChats}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isMobile={isMobile}
          />
        )}

        {tab === 'website' ? (
          <ChatArea
            selectedChat={selectedChat}
            messages={messages}
            setMessages={setMessages}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleSendMessage={handleSendMessage}
            isMobile={isMobile}
            setSelectedChat={setSelectedChat}
            connected={connected}
            messageLoading={messageLoading}
          />
        ) : tab === 'whatsapp' ? (
          <WhatsappChat
            selectedChat={selectedChat}
            messages={messages}
            setMessages={setMessages}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleSendMessage={handleWhatsappTextMsgSend}
            isMobile={isMobile}
            setSelectedImage={setSelectedImage}
            isUploadingImage={isUploadingImage}
            setSelectedChat={setSelectedChat}
            selectedImage={selectedImage}
            connected={connected}
          />
        ) : (
          <InstagramChat
            selectedChat={selectedChat}
            messages={messages}
            setMessages={setMessages}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            // handleSendMessage={handleInstagramMessageSend}
            isMobile={isMobile}
            setSelectedChat={setSelectedChat}
            connected={connected}
          />
        )}

        </div>
      </div>

    </CustomLayout>
  );
}
