import React, { useState, useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import { message, Progress, Button, Spin } from 'antd';
import {LoadingOutlined} from '@ant-design/icons'

export default function Success() {
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false); // Track if the request is successful
  const [loading, setLoading] = useState(true); // Track loading state
  const [checkOutSessionID,setcheckOutSessionID]=useState()


  function setCheckoutID(){
    const params = new URLSearchParams(window.location.search);
    setcheckOutSessionID(params.get('session_id'))
    handleSuccessFullPaymen();

  }

  useEffect(() => {
    setCheckoutID()
  }, []);




  async function handleSuccessFullPaymen() {
    try {
      setProgress(10);
      console.log(checkOutSessionID) 

      const params = new URLSearchParams(window.location.search);
    

      await axios.post(
        `${config.apiUrl}payment/checkout-status/`,
        {
          checkOutSessionID:params.get('session_id'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          onUploadProgress: (progressEvent) => {
            setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100 * 0.5) + 30);
          },
        }
      );

      setProgress(100);
      setIsSuccess(true); // Mark request as successful
      message.success('Subscription successfully processed!');
    } catch (error) {
      console.error('Error:', error);
      message.error('Error processing request');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  }

  const handleGoToDashboard = () => {
    window.location.href = '/user/chatbot'; // Replace with the actual dashboard URL
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} size="large"  />
        
      ) : isSuccess ? (
        <div className="bg-white shadow-md rounded-lg p-8 flex flex-col items-center space-y-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-green-600 w-28 h-28"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h1 className="text-4xl font-bold text-gray-800">Thank You!</h1>
          <p className="text-lg text-gray-600 text-center">
            Your subscription has been successfully activated. <br />
            We’re excited to have you on board. Start exploring our features today!
          </p>

          <Button
            type="primary"
            size="large"
            style={{
              backgroundColor: '#7030f0',
              borderColor: '#7030f0',
            }}
            onClick={handleGoToDashboard}
          >
            Go to Dashboard
          </Button>

         
        </div>
      ) : (
        <div className="bg-white shadow-md rounded-lg p-8 flex flex-col items-center space-y-6">
          <h1 className="text-2xl font-bold text-red-600">Oops!</h1>
          <p className="text-lg text-gray-600 text-center">
            Something went wrong while processing your subscription. Please try again or contact support.
          </p>
        </div>
      )}
    </div>
  );
}
