import React, { useEffect, useState } from 'react';
import { Button, Card, Typography, Spin, message } from 'antd';
import CustomLayout from "../../layouts/Customlayout";
import {ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const CLIENT_ID = config.calendlyClientId; // Replace with your Calendly client ID
const REDIRECT_URI = config.calendlyReturnUri; // Replace with your redirect URI

export default function Calendly() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [calendlyLink, setCalendlyLink] = useState(null);
  const { t } = useTranslation();

  const navigate=useNavigate();

  // Check if the user is authenticated with Calendly
  const checkCalendlyAuthentication = async () => {
    try {
      const response = await axios.get('https://jawebcrm.onrender.com/calendly/auth/calendly/check/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.calendly_url) {
        setIsAuthenticated(true);
        setCalendlyLink(response.data.calendly_url);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking Calendly authentication:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkCalendlyAuthentication();
  }, []);

  // This function will redirect the user to Calendly's OAuth page
  const handleCalendlyLogin = () => {
    setLoading(true);
    const authorizationUrl = `https://auth.calendly.com/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;
    window.location.href = authorizationUrl; // Redirect to Calendly OAuth page
  };

  // Function to handle the OAuth callback
  const handleOAuthCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const authorizationCode = params.get("code");
    setLoading(true);

    if (authorizationCode) {
      try {
        const response = await axios.post('https://jawebcrm.onrender.com/calendly/auth/calendly/check/', {
          code: authorizationCode, // Send authorization code in the body
        }, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        });

        if (response.data.calendly_url) {
          setIsAuthenticated(true);
          setCalendlyLink(response.data.calendly_url);
          message.success("You've Successfully connected your account with Calendly", 3);
        }

      } catch (error) {
        console.error('Error during OAuth token exchange:', error);
      } finally {
        setLoading(false); // Set loading to false when the process is complete
      }
    }
  };

  useEffect(() => {
    if (window.location.search.includes("code=")) {
      handleOAuthCallback();
    }
  }, []);

  return (
    <CustomLayout>
       <div className="p-16  mx-auto bg-gradient-to-r from-blue-50 to-white shadow-xl rounded-xl">

<Button
  icon={<ArrowLeftOutlined />}
  className="mb-6 bg-gray-200 hover:bg-gray-300 border-none px-4 py-2 rounded-lg"
  onClick={() => navigate('/user/integration')}
>
  {t("Back to Integrations")}
</Button>
        <div className="flex-1">
          <Card className="p-6 ">
            <div className="flex items-center gap-4">
              <img
                src="https://logosandtypes.com/wp-content/uploads/2022/09/calendly.svg"
                alt="Calendly Logo"
                className="w-12 h-12"
              />
              <Title level={3}>Connect Your Calendly Account</Title>
            </div>
            <Text className="mb-4">
              Calendly is a scheduling tool that helps you automate meeting bookings, saving you time and avoiding scheduling conflicts. With Calendly, you can easily share your availability and allow others to schedule time with you directly. Simply connect your account and start scheduling!
            </Text>

            {isAuthenticated ? (
              <div className="mt-4">
                <Text>Your Calendly account has been authenticated!</Text>
                <div className="mt-2">
                  <Text>Calendly Link: </Text>
                  <Button
                    type="link"
                    href={calendlyLink}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {calendlyLink}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-6 flex justify-center items-center">
                <Button
                  type="primary"
                  style={{ width: '60%' }}
                  className="bg-blue-500 hover:bg-blue-600 border-none text-white"

                  loading={loading}
                  block
                  size="large"
                  onClick={handleCalendlyLogin}
                  icon={<img src="https://logosandtypes.com/wp-content/uploads/2022/09/calendly.svg" alt="Calendly" className="w-6 h-6" />}
                >
                  Login with Calendly
                </Button>
              </div>
            )}
          </Card>
        </div>
      </div>
    </CustomLayout>
  );
}
