import React, { useState, useEffect } from 'react';
import { Button, Input, Tabs, Tag, Spin } from 'antd';
import { SearchOutlined, CheckOutlined, SettingOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import CustomLayout from '../layouts/Customlayout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

// User Info from LocalStorage
const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
const showOnlyShopify = UserInfo?.mode === 'shopify';

// Integration data with paths
const menuOptions = [
  {
    key: 'Website',
    name: 'Website',
    description: 'Embed chatbot on any website.',
    icon: 'https://cdn-icons-png.flaticon.com/512/3037/3037366.png',
    path: '/integration/website',
    category: "Communication",
  },
  {
    key: 'Wordpress',
    name: 'Wordpress',
    description: 'Easily integrate with Wordpress.',
    icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIHLKV8JwetPwQsdHUSZ4GnSC9IP-6WxD7_Q&s',
    path: '/integration/wordpress',
    category: "Communication",
  },
  {
    key: 'Shopify',
    name: 'Shopify',
    description: 'Seamlessly connect your Shopify store.',
    icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM6onG0BTCip21uyKDKTTaMVp62LNJYTLV5A&s',
    path: '/integration/shopify',
    category: "Productivity",
  },
  {
    key: 'WhatsApp',
    name: 'WhatsApp',
    description: 'Automate WhatsApp messaging.',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png',
    path: '/integration/whatsapp',
    category: "Communication",
  },
  {
    key: 'Zapier',
    name: 'Zapier',
    description: 'Automate workflows with Zapier.',
    icon: 'https://www.svgrepo.com/show/354596/zapier-icon.svg',
    path: '/integration/zapier',
    category: "Developer Tools",
  },
  {
    key: 'Calendly',
    name: 'Calendly',
    description: 'Sync scheduling with Calendly.',
    icon: 'https://logosandtypes.com/wp-content/uploads/2022/09/calendly.svg',
    path: '/integration/calendly',
    category: "Productivity",
  },
  {
    key: 'Instagram',
    name: 'Instagram',
    description: 'Connect Instagram for messaging.',
    icon: 'https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg',
    path: '/integration/instagram',
    status: "coming-soon",
    category: "Social Media",
  },
];

export default function Integration() {
  const { t } = useTranslation();
  const integrationTranslations = t("integration");

  const [searchTerm, setSearchTerm] = useState("");
  const [integrationStatus, setIntegrationStatus] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIntegrationStatus = async () => {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
  
      try {
        const response = await axios.get(`${config.apiUrl}integration/status`, {
          headers: {
            'Authorization': `Token ${token}`, // Add Authorization header
            'Content-Type': 'application/json',
          }
        });
  
        setIntegrationStatus(response.data); // Example: { "WhatsApp": "connected", "Shopify": "connect" }
        console.log(response.data);
  
      } catch (error) {
        console.error("Error fetching integration status:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchIntegrationStatus();
  }, []);

  // Apply filtering based on user type
  const integrations = showOnlyShopify
    ? menuOptions.filter((i) => ["Shopify", "WhatsApp", "Zapier", "Calendly"].includes(i.name))
    : menuOptions.filter((i) => i.name !== "Shopify");

  const filteredIntegrations = integrations.filter((integration) =>
    integration.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <CustomLayout>
      <div className="flex flex-col md:flex-row gap-6 p-6 bg-gray-100 rounded-lg">
        <div className="flex-1 bg-white p-6 rounded-lg shadow">
          <div className="mb-6 flex justify-between items-center">
            <h2 className="text-2xl font-bold">{integrationTranslations.introduction}</h2>
          </div>

          <p className="text-gray-500">{integrationTranslations.introductionNote}</p>

          {/* Search Bar */}
          <div className="my-4">
            <Input
              placeholder={t("Search integrations...")}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Category Tabs */}
          <Tabs defaultActiveKey="1" className="mb-4">
            <Tabs.TabPane tab={t("All Integrations")} key="1">
              <IntegrationList integrations={filteredIntegrations} integrationStatus={integrationStatus} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Communication")} key="2">
              <IntegrationList integrations={filteredIntegrations.filter(i => i.category === "Communication")} integrationStatus={integrationStatus} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Developer Tools")} key="3">
              <IntegrationList integrations={filteredIntegrations.filter(i => i.category === "Developer Tools")} integrationStatus={integrationStatus} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Productivity")} key="4">
              <IntegrationList integrations={filteredIntegrations.filter(i => i.category === "Productivity")} integrationStatus={integrationStatus} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Social Media")} key="5">
              <IntegrationList integrations={filteredIntegrations.filter(i => i.category === "Social Media")} integrationStatus={integrationStatus} loading={loading} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </CustomLayout>
  );
}

// Component for displaying integrations
const IntegrationList = ({ integrations, integrationStatus, loading }) => {
  return (
    <div className="space-y-4">
      {loading ? (
        <div className="flex justify-center py-6">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        integrations.map((integration) => (
          integration.status === "coming-soon" ? (
            <div key={integration.key} className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm opacity-50 cursor-not-allowed">
              <div className="flex items-center gap-4">
                <img src={integration.icon} alt={integration.name} className="w-10 h-10 rounded-md" />
                <div>
                  <h3 className="font-semibold flex items-center">
                    {integration.name}{" "}
                    <Tag color="orange" className="ml-2">
                      Coming Soon
                    </Tag>
                  </h3>
                  <p className="text-gray-500 text-sm">{integration.description}</p>
                </div>
              </div>
              <Button icon={<LockOutlined />} disabled className="border-gray-300">
                Coming Soon
              </Button>
            </div>
          ) : (
            <Link to={integration.path} key={integration.key}>
              <div className="flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-sm hover:bg-gray-100 transition">
                <div className="flex items-center gap-4">
                  <img src={integration.icon} alt={integration.name} className="w-10 h-10 rounded-md" />
                  <div>
                    <h3 className="font-semibold">{integration.name}</h3>
                    <p className="text-gray-500 text-sm">{integration.description}</p>
                  </div>
                </div>
                <Button type={integrationStatus[integration.name] === "connected" ? "default" : "primary"}>
                  {integrationStatus[integration.name] === "connected" ? "Connected" : "Connect"}
                </Button>
              </div>
            </Link>
          )
        ))
      )}
    </div>
  );
};
