import React, { useState, useEffect } from 'react';
import OnboardingSlideshow from './OnboardingComponent/OnboardingSlideshow';
import EmailLoginComponent from './OnboardingComponent/EmailLogin';
import PasswordLoginComponent from './OnboardingComponent/PasswordLogin';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginV2() {
  
  const navigate = useNavigate()
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [isEmailScreen, setIsEmailScreen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/user/chatbot');
    }
  },[])


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');
    if (shop) {
      handleShopifyLogin(shop);
    }
  }, []);

  const HandleLoginWithShopify = () => {
    window.location.href = 'https://apps.shopify.com/jawebchatbot';
  };

  const handleShopifyLogin = (shop) => {
    const redirectUri = "https://crm.jaweb.me/shopify/callback/";
    const clientId = "c2fd0307892fec63ee86a6b6872bcfe5"; // Your Shopify app client ID
    const scopes = "read_products,write_products,read_orders,write_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_product_listings,write_checkouts,write_draft_orders";
    
    const authUrl = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}`;
    
    window.location.href = authUrl;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {windowWidth >= 1000 && <OnboardingSlideshow />}

      <div style={{ flex: '1' }}>
        {
          isEmailScreen ? 
          <EmailLoginComponent
            username={username}
            setUsername={setUsername}
            setIsEmailScreen={setIsEmailScreen}
            HandleLoginWithShopify={HandleLoginWithShopify}
          /> : 
            <PasswordLoginComponent
              
              username={username}
            password={password}
              setPassword={setPassword}
              setIsEmailScreen={setIsEmailScreen}
            // handleLoginBtn={handleLoginBtn}
            HandleLoginWithShopify={HandleLoginWithShopify}
          />
        }      
      </div>
    </div>
  );
}
