import React, { useEffect, useState } from 'react';
import "../../../src/Pages/Css/Integration.css";
import errorImg from "../../assets/fluent-mdl2_error.png";
import facebook_icon from "../../assets/image 655.png";
import { Link,useNavigate } from 'react-router-dom';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './Settingsoptions';
import axios from 'axios';
import { MdVerified } from "react-icons/md";
import config from '../../config';
import { Modal, Button, Input, Steps, message, Spin, Typography, Card} from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getCountryCallingCode } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';


const { Step } = Steps;
const { Title, Text } = Typography;


export default function WhatsApp() {
  const [isLoggedId, setIsLoggedIn] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [currentStep, setCurrentStep] = useState(1); // Track the current step
  const [fbObject, setFbObject] = useState({});
  const [creatingSubaccount, setCreatingSubaccount] = useState(false);
  const [subAccountInfo, setSubAccountInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [value, setValue] = useState()
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [countryCode, setCountryCode] = useState()

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const [companyName, setCompanyName] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [companyWebsite, setCompanyWebsite] = useState("")

  const [validationErrors, setValidationErrors] = useState({});

  const navigate = useNavigate();


  const { t } = useTranslation();
  const integrationTranslations = t("integration")

  useEffect(() => {

    if (JSON.parse(localStorage.getItem('UserObject')).phone_number_id) {
      setCurrentStep(3)
      setRegistrationSuccess(true)
    } else {
      console.log('no');
    }

  }, [])


  const LoadingSpinner = () => {
    return <div>Loading...</div>;
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setProfileName('');
    setPhoneNumber('');
  };

  const handleProfileNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleFormSubmit = () => {
    // Handle form submission logic here, e.g., send data to backend
    console.log(`Profile Name: ${profileName}, Phone Number: ${phoneNumber}`);
    closeModal(); // Close modal after submission
  };

  // console.log("🚀 ~ WhatsApp ~ 22222", isLoggedId);
  const embeddedSignupInfoListener = (event) => {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH" || data.event === "FINISH_ONLY_WABA") {
          const { phone_number_id, waba_id } = data.data;
          console.log({ phone_number_id, waba_id });
          setFbObject({ phone_number_id, waba_id });


          setIsLoggedIn(true);
          setCurrentStep(2); // Move to the next step after login

        } else {
          const { current_step } = data.data;
          console.log(`User did not finish ESU. Last step: ${current_step}`);
        }
      }
    } catch {
      console.log('catched error');
      return;
    }
  };

  const handleLogin = () => {
    onLoginClick();
  };

  const handlePhoneNumber = (e) => {
    if (e) {
      setPhoneNumber(e)
      setValidPhoneNumber(isValidPhoneNumber(e))
      if (!isValidPhoneNumber(e)) {
        // setErrorMessage('Invalid phone number.')
      }
    } else {
      // setErrorMessage('Enter your phone number')
    }
  }

  const handleCountryChange = (country) => {
    const selectedCountryCode = country ? `+${getCountryCallingCode(country)}` : ''
    setCountryCode(selectedCountryCode)
  }
  const onLoginClick = () => {
    setIsLoggedIn(true);
    window.FB.login(
      function (response) {
        console.log("🚀 ~ onLoginClick ~ response:", response);
      },
      {
        config_id: "399880686267907",
        auth_type: "rerequest",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2,
          setup: {
            solutionID: "969683498163827",
          }
        }
      }
    );
  };

  const handleCreateSender = () => {
    setLoading(true);
    console.log('creating sender.........................');
    let formData = new FormData();
    formData.append('friendly_name', 'Test Sender11');

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}whatsapp-subaccount/`, formData, headers)
      .then((res) => {
        console.log('sender created----------------------', res);
        setSubAccountInfo(res.data.response);
        console.log(res.data.response)
        console.log(fbObject)
   
        setLoading(false);
        setShowTick(true);
        setIsModalVisible(true); // Open the modal after successful response
      })
      .catch((error) => {
        console.error('Error creating sender:', error);
        setLoading(false);
        // Handle error scenarios as needed
      });
  };


  const validateProfileName = (name) => {
    return name.trim().length > 0 && name.length <= 50;
  };

  const validateCompanyName = (name) => {
    return name.trim().length > 0 && name.length <= 100;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };



  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^\+?[1-9]\d{1,14}$/; // E.164 format
    return phonePattern.test(phoneNumber);
  };


  const handleRegisterSender = () => {

    const newErrors = {};

    if (!validateProfileName(profileName)) newErrors.profileName = 'Invalid profile name.';
    if (!validateCompanyName(companyName)) newErrors.companyName = 'Invalid company name.';
    if (!validateEmail(companyEmail)) newErrors.companyEmail = 'Invalid email address.';
    // if (!validateWebsite(companyWebsite)) newErrors.webLinks = 'Invalid website URL.';
    if (!validatePhoneNumber(phoneNumber)) newErrors.phoneNumber = 'Invalid phone number.';

    if (Object.keys(newErrors).length === 0) {

      setLoading(true)

      // console.log(phoneNumber);
      const newPhoneNumber = phoneNumber.replace('+', '')
      // console.log(newPhoneNumber);

      let formData = new FormData();
      formData.append('sub_account_id', subAccountInfo.sid);
      formData.append('sub_account_token', subAccountInfo.auth_token);
      formData.append('waba_id', fbObject.waba_id); // embedded signup
      formData.append('sender_id', newPhoneNumber); // input box
      formData.append('profile_name', profileName); // input box

      formData.append('company_name', companyName); 
      formData.append('company_email', companyEmail); 
      formData.append('company_website', companyWebsite); 

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      };

      axios.post(`${config.apiUrl}whatsapp-sender-register/`, formData, headers)
        .then((res) => {
          console.log('sender registered----------------------', res);
          setLoading(false)
          setRegistrationSuccess(true); // Set registration success flag
          setCurrentStep(3); // Move to the last step after successful registration
        })
        .catch((error) => {
          console.error('Error registering sender:', error);
          // Handle error scenarios as needed
        });


    } else {
      setValidationErrors(newErrors);
      console.log(newErrors);
      
    }



  };

  window.addEventListener("message", embeddedSignupInfoListener);

  return (
    <CustomLayout>
      <div className="p-16  mx-auto bg-gradient-to-r from-blue-50 to-white shadow-xl rounded-xl">

        {/* Back Button */}
        <Button
          icon={<ArrowLeftOutlined />}
          className="mb-6 bg-gray-200 hover:bg-gray-300 border-none px-4 py-2 rounded-lg"
          onClick={() => navigate('/user/integration')}
        >
          {t("Back to Integrations")}
        </Button>

        {/* Stepper */}
        <Steps current={currentStep - 1} className="mb-6">
          <Step title={integrationTranslations.whatsappTab.connectFbTab} />
          <Step title={integrationTranslations.whatsappTab.connectBusinessTab} />
          <Step title={integrationTranslations.whatsappTab.allSetTab} />
        </Steps>

        <Card className="shadow-md p-6 rounded-lg border border-gray-100">

              {currentStep === 1 && (
                <div className="text-center">
                  <img src={'https://static-00.iconduck.com/assets.00/whatsapp-icon-2040x2048-8b5th74o.png'} alt="Facebook" className="mx-auto mb-4 w-24 h-24" />
                  <Title level={4}>{integrationTranslations.whatsappTab.integrationNote}</Title>
                  <Text className="block text-gray-500">{integrationTranslations.whatsappTab.subHeading1}</Text>
                  <Button
                    className="mt-6 bg-blue-600 hover:bg-blue-700 text-white px-8 py-6 rounded-lg shadow-md"
                    onClick={handleLogin}
                  >
                    {integrationTranslations.whatsappTab.fbLogin}
                  </Button>
                </div>
              )}

              {currentStep === 2 && (
                <div className="text-center">
                  <Title level={4}>{integrationTranslations.whatsappTab.connectBusinessTab}</Title>
                  <Text className="block text-gray-500">{integrationTranslations.whatsappTab.createNote}</Text>
                  <Button
                    className="mt-6 bg-purple-600 hover:bg-purple-700 text-white px-8 py-5 rounded-lg shadow-md"
                    onClick={handleCreateSender}
                    disabled={isProcessing}
                  >
                    {isProcessing ? <LoadingOutlined /> : "Create Sender"}
                  </Button>
                </div>
              )}

              {currentStep === 3 && registrationSuccess && (
                <div className="text-center">
                  <div className='flex justify-center'>
                  <MdVerified className="text-green-500 text-4xl mb-2" />

                  </div>
                  <Title level={3} className="text-green-500">
                    {integrationTranslations.whatsappTab.allSet}
                  </Title>
                  <Text className="block text-gray-500">
                    {integrationTranslations.whatsappTab.successNote}
                    </Text>
                </div>
              )}

            </Card>
                  <Modal
                        title="Register Sender"
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={[
                          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                            { integrationTranslations.whatsappTab.cancelBtn}
                          </Button>,
                          <Button key="submit" style={{ backgroundColor: 'rgb(127, 40, 248)', color: 'white' }} onClick={handleRegisterSender}>

                            {
                              loading ?        <Spin indicator={<LoadingOutlined spin />} size="small" />
                              : 'Submit'
                            }

                          </Button>,
                        ]}
                      >
                          <label htmlFor="profileName">{ integrationTranslations.whatsappTab.profileName}</label>
                        <Input
                          id="profileName"
                          value={profileName}
                          onChange={handleProfileNameChange}
                          placeholder={ integrationTranslations.whatsappTab.profileNamePlaceholder } 
                          />
                          {validationErrors.profileName && <div style={{ color: 'red' }}>{validationErrors.profileName}</div>}
                          <br /><br />
                          

                          <label htmlFor="companyName">{ integrationTranslations.whatsappTab.companyName}</label>
                        <Input
                          id="companyName"
                          value={companyName}
                          onChange={(e)=> setCompanyName(e.target.value)}
                          placeholder={ integrationTranslations.whatsappTab.companyNamePlaceholder } 
                          />
                          {validationErrors.companyName && <div style={{ color: 'red' }}>{validationErrors.companyName}</div>}
                          <br /><br />
                          

                          <label htmlFor="companyEmail">{ integrationTranslations.whatsappTab.companyEmail}</label>
                        <Input
                          id="companyEmail"
                          value={companyEmail}
                          onChange={(e)=> setCompanyEmail(e.target.value)}
                          placeholder={ integrationTranslations.whatsappTab.companyEmailPlaceholder } 
                          />
                          {validationErrors.companyEmail && <div style={{ color: 'red' }}>{validationErrors.companyEmail}</div>}
                        <br /><br />

                          

                        <label >{ integrationTranslations.whatsappTab.phoneNumber}</label>

                        <PhoneInput
                          defaultCountry='US'
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                          onCountryChange={handleCountryChange}
                          placeholder={ integrationTranslations.whatsappTab.phoneNumberPlaceholder}
                          className='phone_input'
                          />
                          {validationErrors.phoneNumber && <div style={{ color: 'red' }}>{validationErrors.phoneNumber}</div>}

                      </Modal>
      </div>
    </CustomLayout>
  )
}
