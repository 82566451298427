import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Table, Tag, Statistic, Card } from 'antd';
import config from '../../config';
import CustomLayout from '../../layouts/Customlayout';
import { CurrencySymbolMap } from './currency';
import { ArrowDownOutlined, ArrowUpOutlined,LoadingOutlined } from '@ant-design/icons';

export default function ShopifyPage() {
  const [SpinWheel, setSpin] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [orderNumbers, setOrderDataNum] = useState();

  useEffect(() => {
    ShopifyAuth();
  }, []);

  const ShopifyAuth = async () => {
    setSpin(true);

    try {
      const response = await axios.get(`${config.apiUrl}shopify/?username=${localStorage.getItem('username')}`);
      const { access_token, shop } = response.data[0];
      setAuthenticated(true);

      try {
        const ordersResponse = await axios.get(
          `${config.apiUrl}shopify/orders/?access_token=${access_token}&shop=${shop}&username=${localStorage.getItem('username')}`
        );

        setOrderData(ordersResponse.data.orders);
        setOrderDataNum(ordersResponse.data.orders.length);
      } catch (error) {
        console.error('Error fetching Shopify orders:', error);
      } finally {
        setSpin(false);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setAuthenticated(false);
      setSpin(false);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'shipping_address',
      key: 'shipping_address',
      render: (text) => <a>{text?.name}</a>,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, { currency }) => <span>{CurrencySymbolMap[currency]}</span>,
    },
    {
      title: 'Total Price',
      dataIndex: 'current_total_price',
      key: 'current_total_price',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Order Number',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Confirmed',
      key: 'confirmed',
      dataIndex: 'confirmed',
      render: (_, { confirmed }) => {
        const color = confirmed ? 'green' : 'volcano';
        return <Tag color={color}>{confirmed ? 'Confirmed' : 'Not Confirmed'}</Tag>;
      },
    },
  ];

  return (
    <CustomLayout>
      <div className="flex flex-col items-center py-8 px-4 lg:px-20">
        {SpinWheel ? (
          <div className="flex items-center justify-center h-64">
            <Spin indicator={<LoadingOutlined spin />} size="small" />

          </div>
        ) : authenticated ? (
          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
              <Card>
                <Statistic
                  title="Total Orders"
                  value={orderNumbers}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                />
              </Card>
        
            </div>

            <div className="table-container overflow-x-auto shadow-md rounded-lg bg-white p-4">
              <Table
                columns={columns}
                dataSource={OrderData}
                pagination={{ pageSize: 5 }}
                className="ant-table-rounded"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center text-center mt-20">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              Please Connect a Store to This Account
            </h2>
            <p className="text-gray-500 mb-6">
              No store is connected. Link a store to view your Shopify orders and stats.
            </p>
          </div>
        )}
      </div>
    </CustomLayout>
  );
}
