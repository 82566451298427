import React, { useEffect, useState } from 'react';
import { Image, Steps, Button, message, Progress, Spin, Input } from 'antd';
import CustomLayout from '../../layouts/Customlayout';
import Shopify1 from './Images/shopify1.png';
import Shopify2 from './Images/shopify2.png';
import Shopify4 from './Images/shopify4.png';
import axios from 'axios';
import { ArrowLeftOutlined } from '@ant-design/icons';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


export default function Shopify() {
    const [shop, setShop] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [spinWheel, setSpin] = useState(false);
    const { t } = useTranslation();
    const navigate=useNavigate();


    useEffect(() => {
        ShopifyAuth();
    }, []);

    const ShopifyAuth = async () => {
        setSpin(true);
        try {
            const response = await axios.get(`${config.apiUrl}shopify/?username=${localStorage.getItem('username')}`);
            if (response.data.length === 0) {
                setAuthenticated(false);
            } else {
                setAuthenticated(true);
            }
        } catch (error) {
            console.error('Error fetching chatlogs:', error);
            setAuthenticated(false);
        } finally {
            setSpin(false);
        }
    };


    return (
        <CustomLayout>
       <div className="p-16  mx-auto bg-gradient-to-r from-blue-50 to-white shadow-xl rounded-xl">

          <Button
            icon={<ArrowLeftOutlined />}
            className="mb-6 bg-gray-200 hover:bg-gray-300 border-none px-4 py-2 rounded-lg"
            onClick={() => navigate('/user/integration')}
          >
            {t("Back to Integrations")}
          </Button>

        <div className='flex-1'>
          <div style={{ padding: '20px' }}>
            <span style={{ fontSize: '17px', fontWeight: 'bold',paddingRight:'10px' }}>Steps to Install on Jaweb</span>
            <span>Follow These Steps to Install on Jaweb Plugin On Your Shopify Store</span>
          </div>

          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Theme</span>
            <span className='ml-4'>Click on Online , Navigate to Themes and click on the Customize Button</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify1}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify1}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>App Embeds</span>
            <span className='ml-4'>Click on the third options like it shows in the image below</span>

            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify2}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify2}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Toggle JawebChatbot</span>
            <span className='ml-4'>Toggle the JawebChatbot Plugin and click on Save</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify4}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify4}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
         
          
        </div>
      </div>

        </CustomLayout>
    );
}
