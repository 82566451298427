

import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Alert,Button } from 'antd';
import config from '../../config';
import { useState,useEffect } from 'react';
import CustomLayout from '../../layouts/Customlayout';
import { useTranslation } from 'react-i18next';
import {ArrowLeftOutlined } from '@ant-design/icons';




const apiServiceDemo1 = `<div id="chat-root">
</div>
`

const apiServiceDemo2 = `
<script 
src="https://cdn.jsdelivr.net/npm/jawebchatplugin@1.0.3/build/static/js/main.ecf96e28.js"
>
</script>           
`





export default function Website() {

    const [userdata,setUserdata]=useState({})
  
    const navigate=useNavigate()
    
    const { t } = useTranslation();
    const integrationTranslations = t("integration")

    
      //SEYYY
    
    
    
      const [image,setImage]=useState('https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png')
    

    
    
      useEffect(() => {
        loadUserdata();
        
      },[]);
    
    
      const loadUserdata = async () => {
        try {
          // const userdata = await AsyncStorage.getItem('Userdata');
          let userdata = localStorage.getItem('Userdata');
          const userdataVarparsed = JSON.parse(userdata);
    
      
          const response = await fetch(`${config.apiUrl}user/CheckUserAuth`, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${userdataVarparsed?.token}`,
              'Content-Type': 'application/json',
              // Add other headers if needed
            },
          });
      
          if (!response.ok) {
            navigate('/auth/login');
            
          }
      
          const data = await response.json();
    
          
          
          setImage(`https://jawebcrm.onrender.com${data?.image}`);
          setUserdata(data)

      
        } catch (error) {
          // Handle errors
          console.error('Error:', error);
        }
      };
  return (
    <CustomLayout>
    <div className="p-16  mx-auto bg-gradient-to-r from-blue-50 to-white shadow-xl rounded-xl">

      <Button
        icon={<ArrowLeftOutlined />}
        className="mb-6 bg-gray-200 hover:bg-gray-300 border-none px-4 py-2 rounded-lg"
        onClick={() => navigate('/user/integration')}
      >
        {t("Back to Integrations")}
      </Button>

   
            <div className='flex-1'>
            <div className="mt-4">
                <Alert 
                    type="info"
                description={<span>{ integrationTranslations.websiteNote }<strong> index.html</strong></span>} 
                    showIcon 
                /> 
            </div>
			<div className="mt-4">
				<p>{ integrationTranslations.step1 } </p>
                <ol>
                    <li className='mt-4'>
                        <p>{integrationTranslations.step2_1} <code>xyz.html</code> {integrationTranslations.step2_2} </p>
                    </li>

                    <li className='mt-2'>
                        <p>{ integrationTranslations.step3 }</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                            {apiServiceDemo1}
                        </SyntaxHighlighter>
                    </li>
                    <li>
                  <p>{  integrationTranslations.step4 }</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                            {apiServiceDemo2}
                        </SyntaxHighlighter>
                    </li>
                    <li>
                        <strong>{ integrationTranslations.userIdentification }</strong>
                        <p>{ integrationTranslations.step5 }</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                        {`<script>
function setCookie(name, value) {
var expires = "";
var oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
var date = new Date();
date.setTime(date.getTime() + oneDay);
expires = "; expires=" + date.toUTCString();
document.cookie = name + "=" + value + expires + "; path=/";
}
setCookie("company_username", "${localStorage.getItem('UserObject') ? JSON.parse(localStorage.getItem('UserObject')).username : ''}");
 </script>`
                          }

                        </SyntaxHighlighter>
                    </li>
                </ol>
			</div>
			<div className="mt-5">
				<ul>
               
                    <li>
                        <strong>{ integrationTranslations.css }</strong>
                        <p class="mt-1">{ integrationTranslations.step6 }</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                            {`
  <link 
  rel="stylesheet" 
  href="https://cdn.jsdelivr.net/npm/jawebchatplugin@1.0.3/build/static/css/main.314c6c49.css">
  </link>
                            `}
                        </SyntaxHighlighter>
                    </li>
                </ul>
			</div>

            </div>

               

    
            </div>


        

          



 
        </CustomLayout>
  )
}
