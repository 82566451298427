

import React, { useState } from 'react';
import { Avatar, Drawer, Divider, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import config from '../../config';
import AvartarLogo from './WhatsApp Image 2025-01-15 at 10.10.51.jpeg'

const { Option } = Select;

function WhatsappChatview({ data, visible, close, team, onUpdateData,fetchdata,isInvited }) {
  const { t, i18n } = useTranslation();
  const [spinAssign, setSpinAssign] = useState(false);
  const [spinSession, setSpinSession] = useState(false);

  const formatDate = (date) => {
    return date ? dayjs(date).format('MMMM D, YYYY, h:mm A') : 'N/A';
  };

  const handleUpdateAssignee = async (selectedUser, session_id) => {
    setSpinAssign(true);
    try {
      const response = await fetch(`${config.apiUrl}update-assignee/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization':`Token ${localStorage.getItem('token')}` },
        body: JSON.stringify({ user_session_id: session_id, assignee: selectedUser }),
      });

      if (response.ok) {
        const newData = await response.json();
        onUpdateData(newData);
      } else {
        console.error('Failed to update assignee');
      }
    } catch (error) {
      console.error('Error during API request:', error);
    } finally {
      setSpinAssign(false);
    }
  };

  const handleUpdateSession = async (selectedAnswer, session_id) => {
    if (selectedAnswer === 'Yes') {
      setSpinSession(true);
      try {
        const response = await fetch(`${config.apiUrl}update-session/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_session_id: session_id }),
        });

        if (response.ok) {
          const newData = await response.json();
          onUpdateData(newData);
        }
      } catch (error) {
        console.error('Error during API request:', error);
      } finally {
        setSpinSession(false);
      }
    }
  };

  const placement = i18n.language === 'ar' ? 'left' : 'right';

  return (
    <Drawer
      width={350}
      placement={placement}
      onClose={close}
      closable={false}
      open={visible}
    >
      {/* Customer Info */}
      <div className="flex flex-col items-center justify-center mt-6">
        <Avatar
              size={80}
              style={{
                backgroundColor: '#fde3cf',
                color: '#f56a00',
                fontWeight: 'bold',
              }}
            >
              {data?.display_name
                ? data?.display_name
                    .split(' ')
                    .map((n) => n[0]?.toUpperCase()) // Extract and capitalize the first letter of each word
                    .join('')
                    .slice(0, 2) // Initials for avatar
                    
                : 'NA'}
            </Avatar>
        <h2 className="mt-3 text-lg font-semibold">{data?.display_name || 'N/A'}</h2>
      </div>

      <Divider dashed />

      {/* Contact Details */}
      <ul className="w-full px-6">
        <li className="flex justify-between mb-4">
          <h3 className="font-medium">Contact No:</h3>
          <span>+{data?.phone_number || 'N/A'}</span>
        </li>
        <li className="flex justify-between mb-4">
          <h3 className="font-medium">Name:</h3>
          <span>{data?.display_name || 'N/A'}</span>
        </li>
        <li className="flex justify-between mb-4">
          <h3 className="font-medium">Location:</h3>
          <span>{data?.country || 'N/A'}</span>
        </li>
        <li className="flex justify-between mb-4">
          <h3 className="font-medium">Opened:</h3>
          <span>{formatDate(data?.date)}</span>
        </li>
      </ul>

      <Divider dashed />

	  {!isInvited?(
      <div className="flex flex-col items-center mb-6 px-6">
	  <h3 className="font-semibold mb-3">
		{data?.assigned ? 'Assigned to  ' : 'Assign to a member'}

	  </h3>

		  {data?.assigned?
		  (
			  <div className='mt-4 p-4 border rounded'>
				  {data?.assignee}
			  </div>
  
		  ):
		  (
			  <Select
			  placeholder="Select User"
			  style={{ width: '100%' }}
			  onChange={(value) => handleUpdateAssignee(value, data?.user_session_id)}
			  disabled={data?.assigned || spinAssign}
			  loading={spinAssign}
  
			>
			  {team
				?.filter((member) => member?.status==="active")
				.map((member, id) => (
				  <Option className="flex gap-4" value={member?.user?.id}  key={member?.user?.id}>
					  <Avatar src={member?.user?.image}/>

					  <span className='ml-4'>
						  {member?.user?.first_name}
					  </span>
				  </Option>
				))}
			</Select>
		  )	
	  
	  }
	 

	</div>

	  ):(
		null
	  )}
    </Drawer>
  );
}

export default WhatsappChatview;
