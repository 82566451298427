import React, { useState, useEffect } from 'react';
import searchIcon from '../../assets/botTrainingIcons/searchIcon.svg';
import deleteIcon from '../../assets/botTrainingIcons/deleteIcon.svg';
import { message, Image } from 'antd';
import axios from 'axios';
import config from '../../config';

const ShopifyLinks = () => {
  const [nestedUrls, setNestedUrls] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [tableDataStatus, setTableDataStatus] = useState('');
  const [checkedUrls, setCheckedUrls] = useState({});
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const filtered = nestedUrls.filter((link) =>
      link.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUrls(filtered);
  }, [searchTerm, nestedUrls]);

  const handleSelectAllURLs = () => {
    const allChecked = filteredUrls.every((link) => checkedUrls[link.product_link]);
    const newCheckedUrls = { ...checkedUrls };
    filteredUrls.forEach((link) => {
      newCheckedUrls[link.product_link] = !allChecked;
    });
    setCheckedUrls(newCheckedUrls);
  };

  const handleCheckboxChange = (url) => {
    setCheckedUrls((prev) => {
      const newCheckedUrls = { ...prev, [url]: !prev[url] };
      return newCheckedUrls;
    });
  };

  const handleGetNestedUrls = () => {
    setDisableBtn(true);
    axios
      .get(`${config.apiUrl}shopify/start_scraping/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        message.success({
          content: 'Scraping started. Check WebSocket for updates.',
          duration: 2,
        });

        // Setup WebSocket
        const ws = new WebSocket('wss://jawebcrm.onrender.com/ws/scrape-shopify/');
        setSocket(ws);


        ws.addEventListener('open', () => {
          ws.send(
            JSON.stringify({
              user_id: JSON.parse(localStorage.getItem('UserObject')).id,
            })
          );
        });

        ws.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);
         
          if (data.status === 'IN_PROGRESS') {
            if (data.products){
              setNestedUrls((prevUrls) => [...data.products, ...prevUrls]);
            }

          } else if (data.status === 'DONE') {
            setDisableBtn(false);
            message.success({
              content: 'All products have been scraped successfully.',
              duration: 2,
            });
          }
        });

        ws.addEventListener('close', () => {
          setSocket(null);
          console.log('WebSocket disconnected');
        });

        ws.addEventListener('error', (error) => {
          console.error('WebSocket error:', error);
          message.error({ content: 'Connection error occurred', duration: 2 });
          setDisableBtn(false);
        });
      })
      .catch((error) => {
        console.error('Error starting scraping:', error);
        setDisableBtn(false);
        message.error({ content: 'Failed to start scraping', duration: 2 });
      });
  };

  useEffect(() => {
    getStoredURLs();
  }, []);

  const getStoredURLs = () => {
    setTableDataStatus('Loading Data....');

    axios
      .get(`${config.apiUrl}shopify/products/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setNestedUrls(res.data);
        if (res.data.length === 0) {
          setTableDataStatus('No data available');
        }
      })
      .catch(() => {
        setTableDataStatus('No data available');
      });
  };

  const getSelectedLinks = () => {
    return nestedUrls.filter((link) => checkedUrls[link.product_link]);
  };

  const handleDeleteSelectedURL = () => {
    const selectedLinks = getSelectedLinks();
    const urlsToDelete = selectedLinks.map((item) => item.product_link);

    axios
      .delete(`${config.apiUrl}shopify/products/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        data: { websites_url: urlsToDelete },
      })
      .then((res) => {
        setNestedUrls(res.data.remaining_links);
        const newCheckedUrls = { ...checkedUrls };
        urlsToDelete.forEach((url) => {
          delete newCheckedUrls[url];
        });
        setCheckedUrls(newCheckedUrls);
        getStoredURLs();
      })
      .catch((error) => {
        console.error('Error deleting URLs:', error);
      });
  };

  return (
    <>
      <div className="flex">
        <div className="links-content space-y-6">
          <div className="link-section">
            <h2 className="link-section-title">Scrape Shopify Products</h2>
            <p className="link-section-description">
              This feature allows you to extract product data directly from your Shopify store. By clicking the Scrape button, the system will gather product details such as titles, descriptions, prices, and images for further use or integration into your chatbot.
            </p>
            <div className="flex gap-4">
              <button
                className="submit-button"
                onClick={handleGetNestedUrls}
                disabled={disableBtn}
              >
                {disableBtn ? 'Scraping...' : 'Scrape'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="links-table">
        <div className="links-table-header">
          <div className="links-table-search">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="links-table-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img
                src={searchIcon}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                style={{ width: '18px' }}
                alt="Search"
              />
            </div>
          </div>
          {getSelectedLinks().length > 0 && (
            <div className="link-delete-icon">
              <img
                src={deleteIcon}
                style={{ width: '30px', cursor: 'pointer' }}
                onClick={handleDeleteSelectedURL}
                alt="Delete"
              />
            </div>
          )}
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: '600px' }}>
          <table className="links-table-body">
            <thead>
              <tr className="bg-gray-50 text-left text-sm font-medium text-gray-500">
                <th className="links-table-cell">
                  <input
                    type="checkbox"
                    className="rounded"
                    checked={
                      filteredUrls.length > 0 &&
                      filteredUrls.every((link) => checkedUrls[link.product_link])
                    }
                    onChange={handleSelectAllURLs}
                  />
                </th>
                <th className="links-table-cell">Links</th>
                <th className="links-table-cell">Name</th>
                <th className="links-table-cell">Image</th>
                <th className="links-table-cell">Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredUrls.length > 0 ? (
                filteredUrls.map((link) => (
                  <tr key={link.product_link} className="links-table-row">
                    <td className="links-table-cell">
                      <input
                        type="checkbox"
                        className="rounded"
                        checked={checkedUrls[link.product_link] || false}
                        onChange={() => handleCheckboxChange(link.product_link)}
                      />
                    </td>
                    <td className="links-table-cell links-table-status">{link.product_link}</td>
                    <td className="links-table-cell">{link.name}</td>
                    <td className="links-table-cell">
                      <Image
                        width="50px"
                        src={link.image_url}
                        placeholder={
                          <Image preview={false} src={link.image_url} width="50px" />
                        }
                      />
                    </td>
                    <td className="links-table-cell">{link.price}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center text-gray-500">
                    {tableDataStatus}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ShopifyLinks;
