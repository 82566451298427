const ChatMessage = ({ message }) => {
    if (!message) return null;
  
    // Split the message into separate parts based on "|||"
    const messageParts = message.split("|||").map(part => part.trim());
  
    const formatTextWithLinks = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.split(urlRegex).map((part, index) =>
        part.match(urlRegex) ? (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {part}
          </a>
        ) : (
          part
        )
      );
    };
  
    return (
      <div
     
      >
        {messageParts.map((part, index) =>
          part.startsWith("img - ") ? (
            // Extract the image URL and render it as an <img> tag
            <img
              key={index}
              src={part.replace("img - ", "").trim()}
              alt="Product Image"
              className="rounded-md mt-2"
            />
          ) : (
            <p key={index} className="mt-2">{formatTextWithLinks(part)}</p>
          )
        )}
      </div>
    );
  };
  
  export default ChatMessage;
  