import React, { useState, useEffect, useRef } from 'react';
import {
  IoSendSharp,
  IoChevronBack,
  IoBookmarkOutline,
  IoImageOutline,
  IoLinkOutline,
  IoSadOutline,
  IoSad,
} from 'react-icons/io5';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import config from '../../config';
import { message,Switch,Tooltip } from 'antd';
import WhatsappChatview from './WhatsappChatview';
import { UserOutlined,SmileOutlined,RobotOutlined,ClockCircleOutlined} from '@ant-design/icons';
import ChatMessage from './ChatMessage';
import AudioVisualizer from './AudioMessage';
import MapComponent from './MapComponent';

export default function WhatsappChat({selectedChat, messages, setMessages, messageInput, setMessageInput, handleSendMessage, isMobile, setSelectedChat,connected,setSelectedImage,isUploadingImage,selectedImage }) {
    const emojiPickerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [Opened,setOpen]=useState(false)
  const [teamMembers,setTeamMembers]=useState()
  const isInvited = localStorage.getItem("invited") === "true"; 
  const [aiEnabled, setAiEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const fileInputRef = useRef(null);

  // Handle file selection
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file); // Store actual file
    }
  };

 





  const checkChatbotStatus = async (sessionId, sessionType) => {
    try {
      const response = await axios.get(`${config.apiUrl}disable-chatbot/`, {
        params: {
          session_id: sessionId,
          session_type: sessionType,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        return response.data.disable_chatbot;
      }
    } catch (error) {
      console.error('Error fetching chatbot status:', error);
    }
  };

  useEffect(() => {
    const fetchChatbotStatus = async () => {
      const isDisabled = await checkChatbotStatus(selectedChat.user_session_id, 'whatsapp');
      setAiEnabled(isDisabled); // Update toggle based on status
    };

    if (selectedChat) {
      fetchChatbotStatus();
    }
  }, [selectedChat]);


  const handleBotControl = async (checked) => {
    setIsLoading(true); // Start loading

    let formData = new FormData();
    formData.append('session_id', selectedChat.user_session_id);
    formData.append('session_type', 'whatsapp');
    formData.append('toggle_action', checked); // Use the new value of the toggle

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
        Accept: 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}disable-chatbot/`,
        formData,
        headers
      );

      setAiEnabled(checked); // Update the toggle state only on success
    } catch (error) {
      console.error('Error toggling bot control:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };



  const handleUpdateData = (newData) => {
    setSelectedChat(newData);
  };


  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/administration/team/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setTeamMembers(response.data[0]?.memberships || []);
    } catch (error) {
      message.error('Failed to load team members.');
    } finally {
    }
  };

  useEffect(()=>{
    fetchTeamMembers();
  },[])

  const closeUserProfile = () => {
    setOpen(false)
  };


  const handleEmojiClick = (emojiObject) => {
    setMessageInput((prev) => prev + emojiObject.emoji);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  
  
return (
    <main
    className={`flex-grow flex overflow-y-auto flex-col bg-white h-screen ${
      isMobile && !selectedChat ? 'hidden' : 'block'
    }`}
  >
    {selectedChat ? (
      <div className="flex flex-col h-[80vh]">
        <div className="flex items-center justify-between p-4 bg-gray-100 border-b">
          <button
            className="text-purple-600 font-medium sm:hidden"
            onClick={() => setSelectedChat(null)}
          >
            <IoChevronBack size={24} />
          </button>
          <h2 className="font-semibold">{selectedChat?.display_name}</h2>
      
         
          <Tooltip
            title={
              isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                ? "You do not have control over this conversation"
                : aiEnabled
                ? "You are in control of the conversation"
                : "AI is responding to messages"
            }
            placement="bottom"
          >
            <div className="flex items-center gap-2">
              {/* Disable Switch for Invited Users Not Assigned */}
              <Switch
                loading={isLoading}
                disabled={
                  isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                }
                onChange={handleBotControl} // Trigger API call on toggle
                checked={aiEnabled}
                checkedChildren={< UserOutlined/>} // Icon for "AI On"
                unCheckedChildren={<RobotOutlined />} // Icon for "AI Off"
              />
              <span className="text-sm font-medium text-gray-700">
                {aiEnabled ? "Human Control" : "AI Enabled"}
              </span>
            </div>
          </Tooltip>



          <button onClick={()=>{setOpen(!Opened)}} className="text-gray-600 hover:text-[#25D366]">
            <UserOutlined style={{fontSize:25}} />
          </button>
        </div>

        <div className="flex-grow p-4 overflow-y-auto bg-gray-50">
  {/* Connection Status */}
  <div className="flex justify-center">
    {connected === "Connecting" && (
      <div style={{ color: "lightblue", fontSize: "15px" }}>Connecting ••</div>
    )}
    {connected === "Connected" && (
      <div style={{ color: "lightgreen", fontSize: "15px" }}>Connected •</div>
    )}
    {connected === "Disconnected" && (
      <div style={{ color: "lightcoral", fontSize: "15px" }}>Disconnected •</div>
    )}
  </div>

  {messages?.length > 0 ? (
  messages.map((message, id) => (
    <div
      key={id}
      className={`flex flex-col ${
        message?.from_bot ? "items-end" : "items-start"
      } mb-6`}
    >
      {/* If Message Has an Image, Show It */}
      {message?.image_url && (
        <div className="w-fit mb-2">
          <img
            src={message.image_url}
            alt="Chat image"
            className={`rounded-md mt-2 max-w-xs ${
              message?.from_bot ? "border border-gray-300 shadow-sm" : "border border-green-400 shadow-sm"
            }`}
          />
        </div>
      )}

      {/* Show Text Message Only If It's Not [Image] */}
      {message?.message && message?.message !== "[Image]" && (
        <div
          className={`max-w-xs p-4 rounded-lg shadow-md ${
            message?.from_bot ? "bg-gray-600 text-white" : "bg-[#25D366] text-white"
          }`}
        >
          <ChatMessage message={message?.message} />
        </div>
      )}

      {/* Sender Name & Message Status */}
      <div className="flex items-center mt-2 space-x-1">
  
        {message?.from_bot && (
            <span
              className="text-xs px-2 py-1 rounded-full shadow-sm text-gray-700 border border-gray-300 bg-gray-100"
            >
              {message.sender}
            </span>
          )}


        {/* Message Status Icons */}
        {message?.from_bot ? (
          <>
            {message.status === "uploading" && (
              <ClockCircleOutlined style={{ fontSize: 12 }} className="text-yellow-500" />
            )}
            {message.status === "sending" && (
              <ClockCircleOutlined style={{ fontSize: 12 }} />
            )}
            {message.status === "delivered" && (
              <span className="text-blue-500" style={{ fontSize: 12 }}>
                ✔✔
              </span>
            )}
            {message.status === "failed" && (
              <span className="text-red-500" style={{ fontSize: 12 }}>
                ❌
              </span>
            )}
          </>
        ) : (
          // If message is from user, show delivered or read status
          <>
            {message.status === "sending" && (
              <ClockCircleOutlined style={{ fontSize: 12 }} className="text-yellow-500" />
            )}
            {message.status === "sent" && (
              <span className="text-gray-500" style={{ fontSize: 12 }}>
                ✔
              </span>
            )}
            {message.status === "delivered" && (
              <span className="text-blue-500" style={{ fontSize: 12 }}>
                ✔✔
              </span>
            )}
            {message.status === "read" && (
              <span className="text-blue-600" style={{ fontSize: 12 }}>
                ✔✔
              </span>
            )}
          </>
        )}
      </div>
    </div>
  ))
) : (
  <p className="text-center text-gray-500">No messages yet. Start the conversation!</p>
)}


  </div>


  <div className="p-3 bg-white border-t flex items-center gap-3 shadow-md">
      {/* Emoji Button */}
      <button
        type="button"
        onClick={() => setShowEmojiPicker((prev) => !prev)}
        className="text-gray-500 hover:text-purple-600 w-10 h-10 flex items-center justify-center"
        disabled={isUploadingImage}
      >
        <IoSadOutline size={20} />
      </button>

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <div ref={emojiPickerRef} className="absolute bottom-16">
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}

      {/* Image Upload Button */}
      <button
        className="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center"
        onClick={() => fileInputRef.current.click()}
        disabled={isUploadingImage}
      >
        <IoImageOutline size={20} />
      </button>

      {/* Hidden File Input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageSelect}
      />

      {/* Image Preview (if selected) */}
      {selectedImage && (
        <div className="relative">
          <img src={URL.createObjectURL(selectedImage)} alt="Preview" className="w-20 h-20 rounded-md object-cover" />
          <button
            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
            onClick={() => setSelectedImage(null)}
          >
            ✕
          </button>
        </div>
      )}

      {/* Message Input (Enabled while selecting, Disabled only when uploading) */}
      <input
        type="text"
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        placeholder="Send your message..."
        className="flex-grow p-3 border border-gray-300 rounded-full bg-gray-100 focus:outline-none"
        disabled={isUploadingImage}
      />

      {/* Send Button */}
      <button type="button" onClick={handleSendMessage} disabled={isUploadingImage}>
        <IoSendSharp size={24} color={isUploadingImage ? "#B0BEC5" : "#25D366"} />
      </button>
    </div>

      </div>
    ) : (
      <div className="flex-grow flex items-center justify-center">
        <p className="text-gray-500">Select a chat to see the content.</p>
      </div>
    )}
    <WhatsappChatview visible={Opened} isInvited={isInvited}  onUpdateData={handleUpdateData}  close={() => { closeUserProfile() }} data={selectedChat} team={teamMembers}/>

  </main>
  )
}

